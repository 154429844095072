import React, { useState } from "react";
import bootstrap from "bootstrap/dist/css/bootstrap.css";
import Header from "./Components/Header/Header";
import Menu from "./Components/Menu/Menu";
import Content from "./Components/Content/Content";
import "./App.css";
import Services from "./Components/Services/Services";

function App() {
  const [activeSection, setActiveSection] = useState("about"); // Default state

  const [person, setPerson] = useState("");
  const [domain, setDomain] = useState("");
  const [testimonial, setTestimonial] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      person,
      domain,
      testimonial,
    };

    try {
      const response = await fetch(
        "https://alex-suciu.homebuddy.ro/php/saveTestimonial.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      if (response.ok) {
        console.log("Testimonial saved successfully!");
        // Clear the form
        setPerson("");
        setDomain("");
        setTestimonial("");
      } else {
        console.error("Failed to save testimonial.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="App container-fluid">
      <div className="row m-3">
        <div className="App-header col-md-4 rounded-4 p-3 me-md-3">
          <Header />
        </div>
        <div className="App-content col-md rounded-4 px-0 mt-3 mt-md-0">
          <Menu setActiveSection={setActiveSection} />
          <Content activeSection={activeSection} />
        </div>
      </div>
      <div className="App-services rounded-4 row m-3">
        <Services />
      </div>
    </div>
  );
}

export default App;
