import React from "react";
import "./ProjectsMenu.css";

function ProjectsMenu({ data, onProjectSelect }) {
  const handleClick = (description) => {
    onProjectSelect(description); // Pass selected project's description to parent
  };

  // Identify unique project domains
  const uniqueDomains = [...new Set(data.map((item) => item.project_domain))];

  return (
    <div className="ProjectsMenu">
      {uniqueDomains.map((domain, domainIndex) => (
        <div key={domainIndex} className="domain-section">
          <h5 className="domain-category">{domain}</h5>
          <div className="projects-buttons">
            {data
              .filter((item) => item.project_domain === domain)
              .map((item, index) => (
                <button
                  key={index}
                  onClick={() => handleClick(item)}
                  className="project-button"
                >
                  {item.title}
                </button>
              ))}
          </div>
        </div>
      ))}
    </div>
  );
}

export default ProjectsMenu;
