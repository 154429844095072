import React, { useState, useEffect } from "react";
import axios from "axios";
import Testimonial from "./Testimonial";

function Testimonials() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const testimonialsPerPage = 3;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://alex-suciu.homebuddy.ro/php/fetchTestimonials.php`
        );
        setData(response.data.testimonials || []);
      } catch (err) {
        setError(err.message || "An error occurred while fetching data.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  const indexOfLastTestimonial = currentPage * testimonialsPerPage;
  const indexOfFirstTestimonial = indexOfLastTestimonial - testimonialsPerPage;
  const currentTestimonials = data.slice(
    indexOfFirstTestimonial,
    indexOfLastTestimonial
  );

  const totalPages = Math.ceil(data.length / testimonialsPerPage);

  return (
    <div className="testimonials-wrapper">
      <div className="testimonials-container">
        {currentTestimonials.map((item, index) => (
          <Testimonial key={index} item={item} index={index} />
        ))}
      </div>
      <div className="pagination d-flex align-items-center justify-content-center">
        <button
          className="btn btn-link"
          disabled={currentPage === 1}
          onClick={() => setCurrentPage(currentPage - 1)}
        >
          Previous
        </button>
        <span>
          Page {currentPage} of {totalPages}
        </span>
        <button
          className="btn btn-link"
          disabled={currentPage === totalPages}
          onClick={() => setCurrentPage(currentPage + 1)}
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default Testimonials;
