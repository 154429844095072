import "./Service.css";
function Service({ item, index }) {
  // Parse the features JSON string
  const features = JSON.parse(item.features);

  return (
    <div key={index} className="Service">
      <img
        className="service-logo"
        src={`https://alex-suciu.homebuddy.ro/services-logo/${item.img_path}`}
      ></img>
      <h4 className="Service-title">{item.title}</h4>
      <p className="Service-description">{item.description}</p>
      <ul>
        {features.map((feature, index) => (
          <li key={index}>{feature}</li>
        ))}
      </ul>
    </div>
  );
}

export default Service;
