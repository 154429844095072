import React from "react";
import "./Content.css";
import DataProvider from "../DataContext/DataContext";
import AboutMe from "../AboutMe/AboutMe";
import Projects from "../Projects/Projects";
import Testimonials from "../Testimonials/Testimonials";

function Content({ activeSection }) {
  return (
    <div className="Content rounded-4 mt-3 p-3">
      <DataProvider>
        {activeSection === "about" && <AboutMe />}
        {activeSection === "projects" && <Projects />}
      </DataProvider>
      {activeSection === "testimonials" && <Testimonials />}
    </div>
  );
}

export default Content;
