import React, { useContext } from "react";
import { DataContext } from "../DataContext/DataContext";
import "./AboutMe.css";

function AboutMe() {
  const { data, loading, error } = useContext(DataContext);
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="AboutMe">
      {data["cv-header"].map((item, index) => (
        <p
          key={index}
          dangerouslySetInnerHTML={{
            __html: item.about_me.replace(/\n/g, "<br />"),
          }}
        />
      ))}
    </div>
  );
}

export default AboutMe;
