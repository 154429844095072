import React from "react";
import "./Nav.css";


function Nav({setActiveSection}) {

  return (
    <div className="Nav rounded-4 d-flex justify-content-around">
      <button
        className="mx-2 py-2 px-3 rounded m-3"
        onClick={() => setActiveSection("about")}
      >
        About Me
      </button>
      <button
        className="mx-2 py-2 px-3 rounded m-3"
        onClick={() => setActiveSection("projects")}
      >
        My Projects
      </button>
      <button
        className="mx-2 py-2 px-3 rounded m-3"
        onClick={() => setActiveSection("testimonials")}
      >
        Testimonials
      </button>
    </div>
  );
}

export default Nav;
