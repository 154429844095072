import React from "react";
import "./Project.css";

function Project({ description, link, title }) {
  return (
    <div className="Project">
      <p className="ProjectTitle">{title}</p>
      <p
        dangerouslySetInnerHTML={{
          __html: description.replace(/\n/g, "<br />"),
        }}
      />
      <a href={link} className="link-to-project">
        Link to the project
      </a>
    </div>
  );
}

export default Project;
