import React from "react";
import "./Testimonial.css";

function Testimonial({ item, index }) {
  return (
    <div className="Testimonial-container px-5 py-2 mb-3">
      <p className="testimonial mb-0 mt-2">
        <span className="bg-white rounded p-1 me-3 bg-opacity-50">
          {item.domain}
        </span>
        {item.testimonial}
      </p>

      <p className="testimonial-person mt-0 pt-0">- {item.person}</p>
    </div>
  );
}

export default Testimonial;
