import "./Menu.css";
import DataProvider from "../DataContext/DataContext";
import Nav from "./Nav";
import Social from "./Social";
import Cv from "./Cv";

function Menu({setActiveSection}) {
  return (
    <div className="Menu d-lg-flex justify-content-between">
      <DataProvider>
        <Nav setActiveSection={setActiveSection}/>
        <Social />
        <Cv />
      </DataProvider>
    </div>
  );
}

export default Menu;
