import React, { useState, useContext, useRef, useEffect } from "react";
import { DataContext } from "../DataContext/DataContext";

import "./Social.css";
import envelope from "./ico/envelope-solid.svg";
import globe from "./ico/globe-solid.svg";
import linkedin from "./ico/linkedin-brands-solid.svg";
import location from "./ico/location-dot-solid.svg";
import phone from "./ico/phone-solid.svg";
import whatsapp from "./ico/whatsapp-brands-solid.svg";

function Social() {
  const { data, loading, error } = useContext(DataContext);
  const [selectedIcon, setSelectedIcon] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const socialRef = useRef(null); // Ref to track the Social component

  const handleClick = (e) => {
    const iconId = e.target.id;

    if (selectedIcon === iconId) {
      setIsVisible(!isVisible); // Toggle visibility if the same icon is clicked
    } else {
      setSelectedIcon(iconId);
      setIsVisible(true); // Show the div if a new icon is clicked
    }
  };

  // Handle clicks outside the component
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (socialRef.current && !socialRef.current.contains(e.target)) {
        setIsVisible(false); // Hide the details section if clicked outside
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [socialRef]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div
      ref={socialRef}
      className="Social rounded-4 p-3 d-flex flex-column align-items-center justify-content-center mx-lg-3 my-3 my-lg-0"
    >
      <div>
        <img
          src={envelope}
          alt="mail"
          className="mx-2"
          id="email"
          onClick={handleClick}
        ></img>
        <img
          src={globe}
          alt="website"
          className="mx-2"
          id="website"
          onClick={handleClick}
        ></img>
        <img
          src={linkedin}
          alt="linkedin"
          className="mx-2"
          id="linkedin"
          onClick={handleClick}
        ></img>
        <img
          src={location}
          alt="location"
          className="mx-2"
          id="home"
          onClick={handleClick}
        ></img>
        <img
          src={phone}
          alt="phone"
          className="mx-2"
          id="phone"
          onClick={handleClick}
        ></img>
        <img
          src={whatsapp}
          alt="whatsapp"
          className="mx-2"
          id="whatsapp"
          onClick={handleClick}
        ></img>
      </div>
      {isVisible && (
        <div className="Social-details mt-3 bg-light rounded px-2">
          {data["cv-header"].map((item, index) => (
            <p key={index}>{item[selectedIcon]}</p>
          ))}
        </div>
      )}
    </div>
  );
}

export default Social;
