import React, { useState } from "react";
import "./Cv.css";
import file from "./ico/file-lines-regular.svg";
import WrappedViewResumee from "../ViewResumee/ViewResumee";
import WrappedViewResumeeFaang from "../ViewResumeFaang/ViewResumeeFaang";

function Cv() {
  const [popupVisibleView, setPopupVisibleView] = useState("hidden");

  const handleViewClick = () => {
    setPopupVisibleView(popupVisibleView === "hidden" ? "visible" : "hidden");
  };

  return (
    <div className="Cv  rounded-4 p-3 d-flex justify-content-center">
      <img src={file} alt="cv" onClick={handleViewClick}></img>
      <div className={`view-cv-container ${popupVisibleView}`}>
        <div
          className="cv-close-button"
          onClick={() => setPopupVisibleView("hidden")}
        >
          X
        </div>
        <WrappedViewResumeeFaang />
      </div>
    </div>
  );
}

export default Cv;
