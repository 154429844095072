import React, { useState, useEffect } from "react";
import axios from "axios";
import Service from "./Service";
import "./Services.css";

function Services() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const user = "Suciu";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://alex-suciu.homebuddy.ro/api/services.php`
        );
        console.log(response.data); // Check the data structure
        setData(response.data);
      } catch (err) {
        setError(err.message || "An error occurred while fetching data.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="Services">
      <h2>My skill base</h2>
      <div className="Services-container">
        {data["services"].map((item, index) => (
          <Service key={index} item={item} index={index} />
        ))}
      </div>
    </div>
  );
}

export default Services;
